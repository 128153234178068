export default [
    {
      title: 'Dashboard',
      icon: 'HomeIcon',
      route: 'main-dashboard',
      action: 'view',
      resource: 'dashboard',
    },
    {
      header: 'Main',
    },
    {
      title: 'Events',
      icon: 'CalendarIcon',
      action: 'view',
      resource: 'events',
      children: [
        {
          title: 'Upcoming',
          route: 'main-events',
          action: 'view',
          resource: 'events',
        },
        {
          title: 'Finish',
          route: 'main-events-finish',
          action: 'view',
          resource: 'events',
        },
        // {
        //   title: 'Add',
        //   route: 'main-events-add',
        //   action: 'add',
        //   resource: 'events',
        // },
      ],
    },
    {
      title: 'Users',
      icon: 'UserIcon',
      action: 'view',
      resource: 'users',
      children: [
        {
          title: 'List',
          route: 'main-users',
          action: 'view',
          resource: 'users',
        },
        {
          title: 'Add',
          route: 'main-users-add',
          action: 'add',
          resource: 'users',
        },
        {
          title: 'Reports',
          route: 'main-users-stats',
          action: 'view',
          resource: 'users',
        },
      ],
    },
    // {
    //   header: 'Others',
    // },
    // {
    //   title: 'Fields',
    //   icon: 'FileIcon',
    //   route: 'master-fields',
    //   action: 'view',
    //   resource: 'fields',
    // },
    // {
    //   title: 'Feedback Forms',
    //   icon: 'Edit3Icon',
    //   route: 'master-questions',
    //   action: 'view',
    //   resource: 'questions',
    // },
    {
      title: 'Master',
      icon: 'ListIcon',
      action: 'view',
      resource: 'job_titles',
      children: [
        {
          title: 'Job Titles',
          route: 'master-jobs',
          action: 'view',
          resource: 'job_titles',
        },
        {
          title: 'Job Levels',
          route: 'master-job-levels',
          action: 'view',
          resource: 'job_levels',
        },
        {
          title: 'Company Industries',
          route: 'master-industries',
          action: 'view',
          resource: 'industries',
        },
        {
          title: 'Event Categories',
          route: 'master-event-categories',
          action: 'view',
          resource: 'event_categories',
        },
        {
            title: 'Event Formats',
            route: 'master-event-types',
            action: 'view',
            resource: 'event_types',
        },
        {
            title: 'Companies',
            route: 'master-companies',
            action: 'view',
            resource: 'companies',
        },
        {
          title: 'Company Sizes',
          route: 'master-company-sizes',
          action: 'view',
          resource: 'company_sizes',
        },
        {
          title: 'Email Tester',
          route: 'master-email-tester',
          action: 'view',
          resource: 'email_tester',
        },
      ],
    },
  ]
  